import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

import pt from "vuetify/lib/locale/pt";

const vuetify = new Vuetify({
  lang: {
    locales: { pt },
    current: "pt",
  },
  theme: {
    themes: {
      light: {
        greenCartya: "#A7E820",
        blueCartya: "#004B88",
        lightGrayCartya: "#a1a1a1",
        darkGrayCartya: "#4D4D55",
        beigeCartya: "#f8f8f8",
        white: "#FFFFFF",
      },
      dark: {
        greenCartya: "#A7E820",
        blueCartya: "#004B88",
        lightGrayCartya: "#a1a1a1",
        darkGrayCartya: "#4D4D55",
        beigeCartya: "#f8f8f8",
        white: "#FFFFFF",
      },
    },
  },
  icons: {
    iconfont: "mdi" || "mdiSvg",
  },
});

export default vuetify;

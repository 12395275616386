import { modifySession } from '@/services/models/navigation';

const navigation = {
  namespaced: true,
  state: {
    dataNavigation: null,
    rentalNavigation: null,
  },

  getters: {
    dataNavigation: state => state.dataNavigation,
    rentalNavigation: state => state.rentalNavigation,
  },

  mutations: {
    SET_DATA_NAVIGATION(state, payload) {
      state.dataNavigation = payload;
    },

    SET_RENTAL_NAVIGATION(state, payload) {
      state.rentalNavigation = payload;
    },
  },

  actions: {
    setNavigation({ commit, dispatch }, data) {
      commit('SET_DATA_NAVIGATION', data);
    },

    async setRentalNavigation({ commit, dispatch }, data) {
      commit('SET_RENTAL_NAVIGATION', data);
      // await dispatch('rentalCompany/setRentalCompany', data, { root: true });
    },
  },
};

export default navigation;

import {api} from '../api';

const modifySession = async (id) => {
  try {
    const response = await api.get(`admin/session/car_rental_id/${id}`);
    return response
  } catch (error) {
    console.error(error)
  }
}

export {
    modifySession
  }
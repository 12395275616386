export default {
  path: "/login",
  name: "LoginView",
  component: () => import("@/views/LoginView.vue"),
  children: [
    {
      path: "",
      name: "Login",
      component: () => import("@/components/Login/Login.vue"),
    },
    {
      path: "/forgot_password",
      name: "ForgotPassword",
      component: () => import("@/components/ForgotPassword/ForgotPassword.vue"),
    },
    {
      path: "/register_user",
      name: "RegisterUser",
      component: () => import("@/components/RegisterUser/RegisterUser.vue"),
    },
    {
      path: "/reset_password",
      name: "ResetPassword",
      component: () => import("@/components/ResetPassword/ResetPassword.vue"),
    },
  ],
};

import { getCarRentals } from '@/services/models/rentalCompany';

const carRentalCompanies = {
  namespaced: true,
  state: {
    companies: null,
  },

  getters: {
    companies: state => state.companies,
  },

  mutations: {
    SET_DATA_RENTAL_COMPANIES(state, payload) {
      state.companies = payload;
    },
  },

  actions: {
    async setCarRentalCompanies({ commit }, params) {
      try {
        const { data } = await getCarRentals(params);
        const rentals = data;
        commit('SET_DATA_RENTAL_COMPANIES', rentals);
      } catch (error) {
        throw error;
      }
    },
  },
};

export default carRentalCompanies;

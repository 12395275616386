import Vue from 'vue';
import Vuex from 'vuex';
import navigation from './modules/navigation.module.js';
import user from './modules/user.module.js';
import rentalCompanies from './modules/rentalCompanies.module.js';
import reservation from './modules/reservation.module.js';
import fixed from './modules/fixed.module.js';
import apiStatus from './modules/apiStatus.module.js';
import analytics from './modules/analytics.module.js';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    apiStatus,
    user,
    navigation,
    rentalCompanies,
    reservation,
    fixed,
    analytics,
  },
});

export default store;

import {
  reservation_init,
  get_chekout,
  get_reservation_admin,
} from "@/services/models/reservation";

const reservation = {
  namespaced: true,
  state: {
    reservationDate: null,
    reservation: null,
    reservationAdmin: null,
  },

  getters: {
    reservationDate: (state) => state.reservationDate,
    reservation: (state) => state.reservation,
    reservationAdmin: (state) => state.reservationAdmin,
  },

  mutations: {
    SET_DATE(state, payload) {
      state.reservationDate = payload;
    },
    SET_RESERVATION(state, payload) {
      state.reservation = payload;
    },
    SET_RESERVATION_ADMIN(state, payload) {
      state.reservationAdmin = payload;
    },
  },

  actions: {
    async getReservationAdmin({ commit }, data) {
      try {
        const response = await get_reservation_admin(data);
        commit("SET_RESERVATION_ADMIN", response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async reservationDates({ commit }, data) {
      commit("SET_DATE", data);
    },

    async checkout({ commit }) {
      try {
        const response_get_checkout = await get_chekout();
        if (response_get_checkout.sucess) {
          commit("SET_RESERVATION", response_get_checkout);
          return response_get_checkout;
        } else {
          commit("SET_RESERVATION", false);
          return false;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async reservationDataInit({ commit, dispatch }, data) {
      try {
        const response_init = await reservation_init(data);
        if (response_init.status === 200 && response_init.data) {
          dispatch("checkout");
        }
      } catch (error) {
        throw error;
      }
    },
  },
};

export default reservation;

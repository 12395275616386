import { getProfile } from "@/services/models/userDatas";
import moment from "moment";

const user = {
  namespaced: true,
  state: {
    profile: null,
  },

  getters: {
    profile: (state) => state.profile,
  },

  mutations: {
    SET_PROFILE(state, payload) {
      if (payload) {
        payload.initials = payload.full_name.slice(0, 1).toUpperCase();
        payload.name = payload.full_name.split(" ")[0];
        payload.dob = moment.utc(payload.dob).format("DD/MM/YYYY");
      }
      state.profile = payload;
    },
  },

  actions: {
    async fetchAuth({ commit }, data) {
      const res = await getProfile();
      if (res) {
        localStorage.setItem("sessionData", "loggedIn");
        commit("SET_PROFILE", res);
      } else {
        localStorage.clear();
        commit("SET_PROFILE", null);
      }
    },

    setProfile({ commit }, data) {
      commit("SET_PROFILE", data);
    },
  },
};

export default user;

import { api_status } from "@/services/models/apiStatus";

const apiStatus = {
  namespaced: true,
  state: {
    isApiAvailable: true,
  },

  mutations: {
    SET_API_STATUS(state, status) {
      state.isApiAvailable = status;
    },
  },

  actions: {
    async checkApiStatus({ commit }) {
      const isAvailable = await api_status();
      commit("SET_API_STATUS", isAvailable);
    },
  },
};

export default apiStatus;

import { api } from "../api";

const api_status = async () => {
  try {
    const response = await api.get("/apiStatus");
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

export { api_status };

const privacy = {
  path: "/privacy",
  name: "PrivacyView",
  component: () => import("@/views/PrivacyView.vue"),
};

const terms = {
  path: "/terms",
  name: "TermsView",
  component: () => import("@/views/TermsView.vue"),
};

const cookies = {
  path: "/cookies",
  name: "CookiesView",
  component: () => import("@/views/CookiesView.vue"),
};

const cancellation = {
  path: "/cancellation",
  name: "CancellationView",
  component: () => import("@/views/CancellationView.vue"),
};

export {
  privacy,
  terms,
  cookies,
  cancellation
}

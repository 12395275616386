import { api } from '../api';

// Buscar todos os adicionais
const fetchAnalyticsGeneral = async () => {
  console.log('object admin');
  try {
    const response = await api.get('/admin/analytics/general');
    console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    throw error.response?.data || 'Erro ao buscar análises gerais';
  }
};

const fetchAnalyticsRental = async id => {
  console.log(id, 'id');
  try {
    const response = await api.get(`/admin/analytics/rental/${id}`);
    return response.data.data;
  } catch (error) {
    throw error.response?.data || 'Erro ao buscar análises da locadora';
  }
};

export { fetchAnalyticsGeneral, fetchAnalyticsRental };

import { getProfile } from '@/services/models/userDatas';

export default async function (to, from, next) {
  const requiresAuth = to.matched.some(
    record => record.meta.requiresAuth || record.meta.dashboardRequiresAuth
  );

  if (!requiresAuth) {
    return next();
  }

  let profile = null;

  try {
    const response = await getProfile();
    profile = response;
  } catch (error) {
    console.error('Erro ao verificar o perfil de usuário:', error);
    localStorage.clear();

    if (to.path === '/login') {
      return next(false); // Evita navegações redundantes
    }

    return next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  }

  if (!profile) {
    localStorage.clear();

    if (to.path === '/login') {
      return next(false); // Evita navegações redundantes
    }

    return next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  }

  if (to.matched.some(record => record.meta.dashboardRequiresAuth)) {
    const hasAdminAccess =
      profile.permissions?.is_car_rental_admin ||
      profile.permissions?.is_super_admin;

    if (!hasAdminAccess) {
      console.warn('Acesso negado: Permissões insuficientes.');

      if (to.path === '/login') {
        return next(false); // Evita navegações redundantes
      }

      return next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    }
  }

  if (to.name === 'Dashboard' && to.path === '/dashboard') {
    return next({ name: 'DashboardMain' }); // Garante o redirecionamento correto
  }

  return next();
}

const Error200 = {
  path: "/error200",
  name: "Error200",
  component: () => import("@/views/Error200.vue"),
};

const Error404 = {
  path: "*",
  name: "Error404",
  component: () => import("@/views/Error404.vue"),
};

export { Error200, Error404 };

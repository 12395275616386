export default {
  path: '/dashboard',
  name: 'Dashboard',
  redirect: '/dashboard/',
  meta: { dashboardRequiresAuth: true },
  component: () => import('@/views/Admin/Dashboard.vue'),
  children: [
    {
      path: '/',
      name: 'DashboardMain',
      component: () => import('@/components/Admin/Dashboard/Dashboard.vue'),
    },
    {
      path: 'reservation_dash',
      name: 'ReservationDash',
      component: () =>
        import('@/components/Admin/ReservationDash/ReservationDash.vue'),
    },
    {
      path: 'paid_service/:requiresAdminId',
      name: 'PaidService',
      component: () => import('@/components/Admin/PaidService/PaidService.vue'),
    },
    {
      path: 'cars_registration',
      name: 'CarsRegistration',
      component: () =>
        import('@/components/Admin/CarsRegistration/CarsRegistration.vue'),
    },
    {
      path: 'rental_company',
      name: 'RentalCompany',
      component: () =>
        import('@/components/Admin/RentalCompany/RentalCompany.vue'),
    },
    {
      path: 'rental_stations',
      name: 'RentalStations',
      component: () =>
        import('@/components/Admin/RentalStations/RentalStations.vue'),
    },
    {
      path: 'availability',
      name: 'AvailabilityAndPrices',
      component: () =>
        import(
          '@/components/Admin/AvailabilityAndPrices/AvailabilityAndPrices.vue'
        ),
    },
    {
      path: 'rental_additional',
      name: 'RentalAdditional',
      component: () =>
        import('@/components/Admin/RentalAdditional/RentalAdditional.vue'),
    },
    {
      path: 'rental_insurance',
      name: 'RentalInsurance',
      component: () =>
        import('@/components/Admin/RentalInsurance/RentalInsurance.vue'),
    },
    {
      path: 'car_category',
      name: 'CarCategory',
      component: () => import('@/components/Admin/CarCategory/CarCategory.vue'),
    },
    {
      path: 'registered_users',
      name: 'RegisteredUsers',
      component: () =>
        import('@/components/Admin/RegisteredUsers/RegisteredUsers.vue'),
    },
    {
      path: 'payment_methods',
      name: 'PaymentMethods',
      component: () =>
        import('@/components/Admin/PaymentMethods/PaymentMethods.vue'),
    },
    {
      path: 'coupons',
      name: 'Coupons',
      component: () => import('@/components/Admin/Coupons/Coupons.vue'),
    },
    {
      path: 'candidates',
      name: 'Candidates',
      component: () => import('@/components/Admin/Candidates/Candidates.vue'),
    },
    {
      path: 'features',
      name: 'Features',
      component: () => import('@/components/Admin/Features/Features.vue'),
    },
    {
      path: 'feature_flag',
      name: 'FeatureFlag',
      component: () => import('@/components/Admin/FeatureFlag/FeatureFlag.vue'),
    },
    {
      path: 'tutorial',
      name: 'Tutorial',
      component: () => import('@/components/Admin/Tutorial/Tutorial.vue'),
    },
  ],
};

import {
  fetchAnalyticsGeneral,
  fetchAnalyticsRental,
} from '@/services/models/analytics';

const navigation = {
  namespaced: true,
  state: {},

  getters: {
    dataNavigation: state => state.dataNavigation,
    // rentalNavigation: (state) => state.rentalNavigation,
  },

  mutations: {
    SET_DATA_NAVIGATION(state, payload) {
      //   state.dataNavigation = payload;
    },

    SET_RENTAL_NAVIGATION(state, payload) {
      //   state.rentalNavigation = payload;
    },
  },

  actions: {
    async fetchAnalyticsReservation({ commit }, id) {
      console.log(id);
      if (id) {
        const response = await fetchAnalyticsRental(id);
        console.log('response', response);
        // commit('SET_ANALYTICS_RESERVATION', data);;
      }
    },
  },
};

export default navigation;

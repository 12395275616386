import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthenticationRoutes from './auth';
import DashboardRoutes from './dashboard';
import RentalRoutes from './rental';
import authGuard from './authGuard';
import Profile from './profile';
import { privacy, terms, cookies, cancellation } from './privacyPolicy';
import { Error200, Error404 } from './error';

const routes = [];

Vue.use(VueRouter);

routes.push(
  Profile,
  AuthenticationRoutes,
  DashboardRoutes,
  RentalRoutes,
  privacy,
  terms,
  cookies,
  cancellation,
  Error200,
  Error404
);

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savePosition) {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach(authGuard);

export default router;
